const API_URL_LOCAL = 'http://localhost:3001';
const API_URL_DEV = 'https://dev.api.eqsgt.com.br';
const API_URL_PROD = 'https://back.eqsgt.com.br';

const LOGO = 'logo-escola.png';

export const Config = () => {
    function getApiUrl() {
        let apiUrl = API_URL_PROD;
        if (window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('192.168.0') !== -1) {
            apiUrl = API_URL_LOCAL;
        }
        if (window.location.href.indexOf('192.168.0') !== -1) {
            apiUrl = API_URL_LOCAL;
            apiUrl = 'https://back.eqsgt.com.br'; // usa a retaguarda da producao
        }

        if (window.location.href.indexOf('dev') !== -1) {
            apiUrl = API_URL_DEV;
        }
        if (window.location.href.indexOf('homologa') !== -1) {
            apiUrl = API_URL_DEV;
        }
        return apiUrl;
    }

    return {
        apiUrl: getApiUrl(),
        logo: LOGO,
    };
};
